import React from "react";
import Contacts from "./components/Contacts";
import "./app.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"

const App = () => {

  return (
      <Contacts></Contacts>
    
  );
};

export default App;