import React, { useState }  from "react";
import "../app.css";
import { db } from "../firebase";
//import {send} from "emailjs-com";
import QRCode from "qrcode.react";
import Logo from '../img/BRITS_Logo.png';
import Feature4 from '../img/img1.png';
import Feature1 from '../img/Date_Icon.svg';
import Feature2 from '../img/Map_Icon.svg';
import Feature3 from '../img/Team_Disscussing_Icon.svg';

const Contact = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lookingfor, setLookingfor] = useState("");
  const [qr, setQr] = useState(false);
  const [form, setForm] = useState(true);
  const [loader, setLoader] = useState(false);
  const[error,setError] = useState(false)
  const[erroremail,setErrorEmail] = useState(false)
  const[errorphone,setErrorPhone] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    db.collection("contacts")
      .add({
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
        lookingfor: lookingfor,
      })
      .then(() => {
        setLoader(false);
       // alert("Your details has been submitted👍");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    //setFirstname("");
    //setLastname("");
    //setEmail("");
    //setPhone("");
    //setLookingfor("");
    setQr(true);
    setForm(false);

    //send("service_xkjab3n","template_1p72wz6",{firstname,lastname,email,phone},"kHzX3mYhGDAgBARMr").then(res=>{console.log(res,email)}).catch(err=> console.log(err))
  };

  const errorHandling = () =>{
    if(firstname.length===0||lastname.length===0||email.length===0||phone.length===0||lookingfor.length===0){
      setError(true)
    }
    if(email.length>0){
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(regex.test(email) === false){
          setErrorEmail(true)
        }
        else{
          setErrorEmail(false)
        }
    }
    if(phone.length>0){
      const regex = /^[0-9]{10}$/
        if(regex.test(phone) === false){
          setErrorPhone(true)
        }
        else{
          setErrorPhone(false)
        }
    }

  }
  
  return (
    <React.Fragment>
        {form === true && 
          <section class="bg-account-pages vh-100">
          <div class="display-table">
             <div class="display-table-cell">
                <div class="container">
                   <div class="no-gutters align-items-center row">
                      <div class="col-lg-12">
                         <div class="login-box">
                            <div class="align-items-center no-gutters row">
                               <div class="width_60 col-lg-6  mb_auto_c mt_ld_c display">
                                  <div class="bg-light pt_c">
                                     <div class="row justify-content-start mt_c">
                                        <h1 class="heading">PHOENIX REACT JS MEET UP GROUP</h1>
                                        
                                        <div class="container">
                                           <div class="row">
                                              <div class="col-lg-6">
                                                 <div class="mb-3 sub_heading">https://www.meetup.com/phoenixreactjs/</div>
                                                 <div class="data">We are the largest tech meetup in the Phoenix valley with over 2,500 members on our Meet Up group and up to 200 regular attendees for monthly in person events. We have cultivated an environment where web developers come to learn the latest and greatest technologies. We built a massive following from bringing in top experts in the web development field to bring their expertise to share with Phoenix residents.</div>
                                                 <div class="mb-3 mt-3 sub_heading">Meet Up Group Details</div>
                                                 <div class="img_div">
                                                    <div class="img_flex">
                                                       <img src={Feature2} class="img-fluid" alt=""/>
                                                       <p class="font_xs">3rd Thursday of the Month</p>
                                                    </div>
                                                    <div class="img_flex">
                                                       <img src={Feature1} class="img-fluid" alt=""/>
                                                       <p class="font_xs">Carvana Hq, Tempe, Az </p>
                                                    </div>
                                                    <div class="w-h-third_img ">
                                                       <img src={Feature3} class="img-fluid mt_c_img" alt=""/>
                                                       <p class="font_xs">Speakers Cover variety of development topics </p>
                                                    </div>
                                                 </div>
                                              </div>
                                              <div class="col-lg-6">
                                                 <div class="mb-3 img_c"><img src={Feature4} class="img-fluid" alt=""/></div>
                                              </div>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div class="width_40 col-lg-6">
                                  <div class="justify-content-end row">
                                     <div class="col-lg-11">
                                        <div class="pt-4">
                                           <div class="text-center mt-3"><img src={Logo} class="img-fluid" alt=""/></div>
                                           <div class="pt-3 custom-form">
                                           <form class="">
                                                 <div>
                                                    <label for="firstname" class="form-label">First Name</label><input type="text" name = "firstname" value={firstname} onBlur={errorHandling} onChange={(e) => setFirstname(e.target.value)} className="form-control" id="firstname" placeholder="First Name" />
                                                    <div class="err_div">
                                                    {error && firstname.length<= 0 ? <p className="err_msg">First Name cannot be empty</p>: ""}
                                                    </div>
                                                 </div>
                                                 <div>
                                                    <label for="lastname" class="form-label">Last Name</label><input type="text" name = "lastname" value={lastname} onBlur={errorHandling} onChange={(e) => setLastname(e.target.value)} className="form-control" id="lastname" placeholder="Last Name" />
                                                    <div class="err_div">
                                                    {error && lastname.length<= 0 ? <p className="err_msg">Last Name cannot be empty</p>: ""}
                                                    </div>
                                                 </div>
                                                 <div>
                                                    <label for="email" class="form-label">Email</label><input type="email" name = "email" value={email} onBlur={errorHandling} onChange={(e) => setEmail(e.target.value)} className="form-control" id="email" placeholder="Enter Email"/>
                                                    <div class="err_div">
                                                    {error && email.length<= 0 ? <p className="err_msg">Email cannot be empty</p>: erroremail ? <p className="err_msg">Email is not vaild</p> : "" }
                                                    </div>
                                                 </div>
                                                 <div>
                                                    <label for="Phone" class="form-label">Phone</label><input type="text" name = "phone" value={phone} onBlur={errorHandling} onChange={(e) => setPhone(e.target.value)} className="form-control" id="userpassword" placeholder="Enter Phone" />
                                                    <div class="err_div">
                                                    {error && phone.length<= 0 ? <p className="err_msg">Phone cannot be empty</p>: errorphone ? <p className="err_msg">Phone Number is not vaild</p> : ""}
                                                    </div>
                                                 </div>
                                                 <div>
                                                    <label for="lookingfor" class="form-label">Looking For</label>
                                                    <select value={lookingfor} className="form-control" onBlur={errorHandling} onChange={(e) => setLookingfor(e.target.value)}>
                                                       <option>Select an option</option>
                                                       <option>Learn React</option>
                                                       <option>Improving Skills</option>
                                                       <option>Networking</option>
                                                    </select>
                                                    <div class="err_div">
                                                    {error && lookingfor.length<= 0 ? <p className="err_msg">Please Select any one option</p>: ""}
                                                    </div>
                                                 </div>
                                                 <div class="mt-3 mb_c"><button type="button" onClick={handleSubmit} class="btn btn-primary btn-block btn_color btn btn-secondary d-block w-100">Submit</button></div>
                                              </form>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>
        }
        {qr === true && 
        <div className="success_div">
          <h2 className="success_heading">Thank you For Registering!!!!!!!</h2>
          <div className="success_message">Details are successfully submitted to database, scan the QR to get your details</div>
          <QRCode  className="qrcode" value={`"First Name=${firstname},Last Name=${lastname},Email=${email},Phone=${phone},LookingFor=${lookingfor}"`}></QRCode>
        </div>
        }
    </React.Fragment>
  );
};

export default Contact;