import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAZWusFldRJ7-7rW4XKcAqVFWdnTWf1IBs",
  authDomain: "qrapp-9712b.firebaseapp.com",
  projectId: "qrapp-9712b",
  storageBucket: "qrapp-9712b.appspot.com",
  messagingSenderId: "453286873252",
  appId: "1:453286873252:web:51d1f59b731f7c318b7e31",
  measurementId: "G-YNN5ELD9E7"
  
});

var db = firebaseApp.firestore();

export  {db};